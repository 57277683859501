var settings = (function () {
    return {
        version: 'WETAG-19112024',
        restUrl: 'https://web.wetag.ar/app',
        contentUrl: 'https://web.wetag.ar/content',
        token: '',
        member: {},
        applications: {},
        trip: {},
        subprogram: {}
    };
})();

var videoTutorialOptions = {
    height: 300,
    playerVars: {
        enablejsapi: 1,
        controls: 1,
        modestbranding: 1,
        rel: 0,
        origin: "https://app.wetag.ar",
        showinfo: 0,
    }
};

export {
    settings,
    videoTutorialOptions
};
