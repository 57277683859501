import { settings, videoTutorialOptions } from '../libraries/settings.js';
import { toast } from '../libraries/toast.js';
import { db } from '../libraries/db.js';

import YouTubePlayer from 'youtube-player';
import StarRating from 'star-rating.js'
import '/src/assets/css/star-rating.css';

App.controller('upload-form', function (page, data) {
    this.restorable = false;

    $(page).find(".upload-title").append(data.name);
    if (data.instructions) {
        $(page).find(".upload-details").html(data.instructions);
    } else {
        $(page).find(".upload-details").remove();
    }
    // Parse Fields & Values
    if (data.fields) {
        let fields = {};
        $.each(data.fields, function (i, options) {
            fields["filecontent[" + options.name + "]"] = options;
        });
        $(page).find(".form-wrap").form(fields, { classes: 'rounded pt-2 bg-white' });

        /*
        let filecontent = {};
        if (data.filecontent) {
          $.each(data.filecontent, function(name, options) {
            filecontent["filecontent[" + name + "]"] = options;
          });
          //$(page).find('.form-wrap').fill(filecontent);
        }
        */
    }

    $(page).on('appReady', function () {
        // Photo Example
        if (data.example) {
            $(page)
                .find(".upload-example")
                .html('<img src="' + settings.contentUrl + '/others/actions/' + data.example + '" class="img-fluid rounded">')
                .show();
        } else {
            $(page).find(".upload-example").remove();
        }

        // Video Tutorial
        if (data.video) {
            let player = YouTubePlayer("upload-video", Object.assign({
                videoId: data.video,
                width: $(page).find("#upload-video").width()
            }, videoTutorialOptions));
            $(page).find(".upload-video").css('visibility', 'visible');
        } else {
            $(page).find(".upload-video").remove();
        }

        // Rating
        var ratingStars = new StarRating('.rating-stars', {
            tooltip: false
        });
        var ratingEmotions = new StarRating('.rating-emotions', {
            prebuilt: true,
            maxStars: 5,
            tooltip: false
        });


        $(page).find('.app-loader').fadeOut();

        let isUploading = false;
        $(page)
            .find(".upload-step-form")
            .on("click", function () {
                if (!isUploading) $(page).find(".form-form").trigger("submit");
            });

        $(page)
            .find(".form-form")
            .on("submit", function (e) {
                if (isUploading) return false;
                isUploading = true;

                e.preventDefault();
                e.stopPropagation();

                $(page).addClass('app-block-touch');
                if ($(this).isValid()) {
                    toast().show('Guardando...', 'warning');

                    fetch(settings.restUrl + '/application/' + settings.trip.application.application_id + '/form', {
                        method: 'POST',
                        headers: {
                            'X-WETAG-Token': settings.token,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams({
                            member_id: settings.member.member_id,
                            step_form_id: data.step_form_id,
                            name: data.name,
                            form_id: data.form_id
                        }) + '&' + $(this).serialize()
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            App.load('application', { tripId: db().getCurrentTripId() });
                            $(page).removeClass('app-block-touch');
                            toast().show(response.html, response.class);
                        })
                        .catch(error => {
                            isUploading = false;
                            $(page).removeClass('app-block-touch');
                        });

                } else {
                    isUploading = false;
                    $(page).removeClass('app-block-touch');
                }
            });
    });
});
