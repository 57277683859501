import { auth } from '../libraries/auth.js';

import { tools } from '../libraries/tools.js';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import Columns from 'columns.js';
import PullToRefresh from 'pulltorefreshjs';

App.controller('content', function (page, data) {
    auth().checkToken();

    setTimeout(() => $(page).find('.app-loader').fadeOut(), 200);

    let listContent = $(page).find('.content-list');
    if (navigator.onLine) {
        var fetchPostsHeaders = new Headers();
        fetchPostsHeaders.append('pragma', 'no-cache');
        fetchPostsHeaders.append('cache-control', 'no-cache');
        fetch('https://wetag.ar/wp-json/wp/v2/posts?categories=21&per_page=50&_embed&_' + new Date().getTime())
            .then((response) => {
                return response.json();
            })
            .then((posts) => {
                if (posts.length > 0) {
                    $.each(posts, function (i, post) {
                        let coverSizes = false;
                        if (
                            'wp:featuredmedia' in post._embedded
                            && post._embedded['wp:featuredmedia'].length > 0
                            && post._embedded['wp:featuredmedia'][0].media_details.sizes
                            && 'media_details' in post._embedded['wp:featuredmedia'][0]) {
                                coverSizes = post._embedded['wp:featuredmedia'][0].media_details.sizes;
                        }

                        let cover = false;
                        if (coverSizes) {
                            if ('medium' in coverSizes) {
                                cover = coverSizes.medium.source_url;
                            } else {
                                cover = coverSizes.full.source_url;
                            }
                        }

                        let item = $('<section>', {
                            class: 'post drop-shadow-dark-left mb-3',
                            html: '<h3 class="m-0 p-2 p-md-4 text-white">' + post.title.rendered + '</h3>',
                        });
                        if (cover) item.css('background-image', 'url(' + cover + ')');

                        item.data('args', {
                            title: post.title.rendered,
                            excerpt: post.excerpt.rendered,
                            content: post.content.rendered,
                            date: post.modified,
                            cover: cover,
                            acf: post.acf
                        });

                        item.on('click', function () {
                            App.load('reader', $(this).data('args'))
                        });
                        listContent.append(item);
                    });
                }
            })
            .then(function () {
                let grid = new Columns(listContent[0], {
                    columns: 2
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    $(page).on('appReady', function () {
        gtag('event', 'screen_view', { screen_name: 'Explorar' });

        tools().showSupportNav(page);

        // Pull to Refresh
        PullToRefresh.init({
            mainElement: '.app-content',
            triggerElement: '.page-content',
            instructionsPullToRefresh: 'Arrastrá hacia abajo para actualizar',
            instructionsReleaseToRefresh: 'Soltá para actualizar',
            instructionsRefreshing: 'Actualizando',
            onRefresh() {
                window.location.reload();
            },
            shouldPullToRefresh: function () {
                return !this.mainElement.scrollTop;
            }
        });
    });
});
