import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

jQuery.fn.form = function (data = false, options) {
    var settings = jQuery.extend({
        id: "form",
        classes: false,
    },
        options
    );
    var target = this;
    var container = jQuery("<div>", {
        class: "app-fields"
    });
    var form = jQuery("<form>", {
        class: settings.id + "-form",
        autocomplete: "off",
    });

    if (settings.classes) container.addClass(settings.classes);

    if (data) {
        format(data);
    }

    function format(fields) {
        if (fields) {
            jQuery.each(fields, function (id, field) {
                if (Number.isInteger(id)) id = field.name; // Fix for Platform

                field.required = field.required || false;
                field.col = field.col || 6;
                field.colMD = field.colMD || field.col;
                field.label = field.label || "";
                field.value = field.value || false;
                field.readonly = field.readonly || false;
                field.classes = field.classes || false;
                field.placeholder = field.placeholder || '';
                field.description = field.description || '';

                var formItem = jQuery("<div>", {
                    class: "app-field field-type-" + field.type,
                });
                var formInput = jQuery("<input>", {
                    class: "app-input"
                });

                if (
                    field.type != "hidden" &&
                    field.type != "spacer" &&
                    field.type != "div" &&
                    field.type != "button" &&
                    field.type != "submit" &&
                    field.type != "title" &&
                    field.type != 'checkboxes'
                ) {
                    formItem.append(
                        '<label for="field-' + id + '">' + field.label + "</label>"
                    );
                }

                switch (field.type) {
                    case "date":
                    case "time":
                        formInput.attr({
                            type: field.type,
                            size: 10
                        });
                        break;
                    case "datetime":
                        formInput.attr({
                            type: "datetime-local",
                            size: 10
                        });
                        break;
                    case "textarea":
                        formInput = jQuery("<textarea>", {
                            class: "app-input",
                            rows: 7
                        });
                        break;
                    case "checkbox":
                        formInput.attr({
                            type: "checkbox",
                            value: field.value || 1
                        });
                        break;
                    case "number":
                        formInput.attr({
                            type: 'number',
                            pattern: '[0-9]*',
                            inputmode: 'numeric',
                            placeholder: field.description
                        });
                        break;
                    case "rating-emotions":
                        formInput = jQuery('<span>', {
                            class: "gl-star-rating",
                            html:
                                '<select class="rating-emotions" name="' + id + '">' +
                                '<option value=""></option>' +
                                '<option value="5">5 Estrellas</option>' +
                                '<option value="4">4 Estrellas</option>' +
                                '<option value="3">3 Estrellas</option>' +
                                '<option value="2">2 Estrellas</option>' +
                                '<option value="1">1 Estrellas</option>' +
                                '</select>' +
                                '<span class="gl-star-rating--stars rounded">' +
                                '<span data-value="1" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFA98D" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M12 14.6c1.48 0 2.9.38 4.15 1.1a.8.8 0 01-.79 1.39 6.76 6.76 0 00-6.72 0 .8.8 0 11-.8-1.4A8.36 8.36 0 0112 14.6zm4.6-6.25a1.62 1.62 0 01.58 1.51 1.6 1.6 0 11-2.92-1.13c.2-.04.25-.05.45-.08.21-.04.76-.11 1.12-.18.37-.07.46-.08.77-.12zm-9.2 0c.31.04.4.05.77.12.36.07.9.14 1.12.18.2.03.24.04.45.08a1.6 1.6 0 11-2.34-.38z"></path></svg></span>' +
                                '<span data-value="2" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFC385" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M12 14.8c1.48 0 3.08.28 3.97.75a.8.8 0 11-.74 1.41A8.28 8.28 0 0012 16.4a9.7 9.7 0 00-3.33.61.8.8 0 11-.54-1.5c1.35-.48 2.56-.71 3.87-.71zM15.7 8c.25.31.28.34.51.64.24.3.25.3.43.52.18.23.27.33.56.7A1.6 1.6 0 1115.7 8zM8.32 8a1.6 1.6 0 011.21 2.73 1.6 1.6 0 01-2.7-.87c.28-.37.37-.47.55-.7.18-.22.2-.23.43-.52.23-.3.26-.33.51-.64z"></path></svg></span>' +
                                '<span data-value="3" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFD885" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M15.33 15.2a.8.8 0 01.7.66.85.85 0 01-.68.94h-6.2c-.24 0-.67-.26-.76-.7-.1-.38.17-.81.6-.9zm.35-7.2a1.6 1.6 0 011.5 1.86A1.6 1.6 0 1115.68 8zM8.32 8a1.6 1.6 0 011.21 2.73A1.6 1.6 0 118.33 8z"></path></svg></span>' +
                                '<span data-value="4" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFD885" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M15.45 15.06a.8.8 0 11.8 1.38 8.36 8.36 0 01-8.5 0 .8.8 0 11.8-1.38 6.76 6.76 0 006.9 0zM15.68 8a1.6 1.6 0 011.5 1.86A1.6 1.6 0 1115.68 8zM8.32 8a1.6 1.6 0 011.21 2.73A1.6 1.6 0 118.33 8z"></path></svg></span>' +
                                '<span data-value="5" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFD885" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M16.8 14.4c.32 0 .59.2.72.45.12.25.11.56-.08.82a6.78 6.78 0 01-10.88 0 .78.78 0 01-.05-.87c.14-.23.37-.4.7-.4zM15.67 8a1.6 1.6 0 011.5 1.86A1.6 1.6 0 1115.68 8zM8.32 8a1.6 1.6 0 011.21 2.73A1.6 1.6 0 118.33 8z"></path></svg></span>' +
                                '</span>'
                        });

                        break

                    case 'checkboxes':
                        formInput = jQuery('<div>', {
                            class: 'app-field app-type-checkboxes ' + id + ' size-' + field.col + ' size-md-' + field.colMD,
                            html: '<label>' + field.label + (field.description ? '<div class="text-secondary mt-1">' + field.description + '</div>' : '') + '</label>'
                        });
                        if (field.required) formInput.addClass('required');

                        if ('options' in field && Object.keys(field.options).length > 0) {
                            jQuery.each(field.options, function (order, option) {
                                let checkWrapper = jQuery('<div>', {
                                    class: 'app-field field-type-checkbox'
                                });
                                checkWrapper.append(
                                    '<label for="field-' + id + '-' + order + '">' + option.label + "</label>"
                                );
                                checkWrapper.append(
                                    jQuery('<input>', {
                                        type: 'checkbox',
                                        value: option.value,
                                        required: field.required,
                                        id: 'field-' + id + '-' + order,
                                        name: id + '[]',
                                        data: { min: field.min_length || 0, max: field.max_length || 0 }
                                    })
                                );

                                formInput.append(checkWrapper);
                            });
                        }

                        break;
                    case 'rating':
                    case 'select':
                        formInput = jQuery("<select>", {
                            class: "app-input"
                        });
                        if ("options" in field && Object.keys(field.options).length > 0) {
                            jQuery.each(field.options, function (order, option) {
                                option.selected = option.selected || false;
                                option.data = option.data || false;
                                formInput.append(
                                    jQuery("<option>", {
                                        value: option.value,
                                        text: option.label,
                                        selected: option.selected,
                                        "data-data": option.data,
                                    })
                                );
                            });
                        }

                        if (field.type === 'rating') {
                            formInput.addClass('rating-stars');
                        }

                        break;
                    case "button":
                        formInput = jQuery("<div>", {
                            text: field.label,
                            class: "app-button",
                            "data-clickable-class": "active",
                        });
                        break;
                    case "submit":
                        formInput.attr({
                            type: 'submit'
                        });
                    case "div":
                        formInput = jQuery("<div>", {
                            text: field.label
                        });
                        if (field.description) {
                            let text = field.description;
                            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            formInput.append('<div>' + text + '</div>');
                        }
                        break;
                    case "title":
                        formInput = jQuery("<div>", {
                            html: '<h4 class="m-0">' + field.label + '</h4>',
                            class: 'p-3 pb-2'
                        });
                        if (field.description) {
                            let text = field.description;
                            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            formInput.append('<div>' + text + '</div>');
                        }
                        break;
                    case "spacer":
                        formInput = jQuery("<div>");
                        break;
                    default:
                        formInput.attr({
                            type: field.type,
                            placeholder: field.description
                        });
                }

                formItem.addClass('field-' + id);
                formItem.addClass('size-' + field.col);
                formItem.addClass('size-md-' + field.colMD);
                formInput.attr({
                    name: id,
                    id: 'field-' + id
                });

                if (field.required) {
                    formItem.addClass('required');
                    if (field.type === 'rating-emotions') {
                        formInput.find('select').attr('required', true);
                    } else {
                        formInput.attr('required', true);
                    }
                }

                if (field.value) formInput.attr("value", field.value);
                if (field.readonly) formInput.attr("readonly", true);
                if (field.classes) formInput.addClass(field.classes);
                if (field.min_length) formInput.attr("min", field.min_length);
                if (field.max_length) formInput.attr("max", field.max_length);

                if (field.data) {
                    if (Array.isArray(field.data)) {
                        jQuery.each(field.data, function (i, attr) {
                            formInput.attr("data-" + attr, true);
                        });
                    } else if (typeof field.data === "object") {
                        jQuery.each(field.data, function (attr, value) {
                            formInput.attr("data-" + attr, value);
                        });
                    }
                }

                if (field.type === 'submit' || field.type === 'hidden' || field.type === 'checkboxes') {
                    container.append(formInput);
                } else {
                    container.append(formItem.append(formInput));
                }
            });
        }

        form.append(container);
        jQuery(target).html(form);
    }
    return jQuery(target);
};

jQuery.fn.fill = function (data = false) {
    var form = jQuery(this);
    if (data) {
        fill(form, data);
    }

    function fill(form, data) {
        form.data("data", data);
        if (Object.keys(data).length > 0) {
            jQuery.each(data, function (field, value) {
                var input;
                for (var c = 0; c < 3; c++) {
                    switch (c) {
                        case 0:
                            input = form.find('*[name="' + field + '"]');
                            break;
                        case 1:
                            input = form.find('*[name="' + field + '[]"]');
                            if (input.is("select")) {
                                input.find("option").prop("selected", false);
                            } // Clean Multiple Select
                            break;
                        case 2:
                            input = form.find('*[name="' + field + '[1]"]');
                            break;
                    }
                    if (input.length > 0) {
                        break;
                    }
                }

                if (input.length > 0 && value) {
                    if (Array.isArray(value) || jQuery.isPlainObject(value)) {
                        if (input.is("select")) {
                            input.find("option").prop("selected", false); // Clean Multiple Select
                            jQuery.each(value, function (i, val) {
                                input.find('[value="' + val + '"]').prop("selected", true);
                            });
                        } else if (input.attr("type")) {
                            jQuery.each(value, function (i, val) {
                                switch (input.attr("type")) {
                                    case "datetime-local":
                                        form
                                            .find('*[name="' + field + "[" + i + ']"]')
                                            .val(moment(val).format("YYYY-MM-DD[T]HH:mm:ss"));
                                        break;
                                    case "date":
                                        form
                                            .find('*[name="' + field + "[" + i + ']"]')
                                            .val(moment(val).format("YYYY-MM-DD"));
                                        break;
                                    case "checkbox":
                                        form
                                            .find('*[name="' + field + '[]"][value="' + val + '"]')
                                            .prop("checked", true);
                                        break;
                                }
                            });
                        }
                    } else if (input.is(":radio") || input.is(":checkbox")) {
                        form
                            .find('*[name="' + field + '"][value="' + value + '"]')
                            .prop("checked", true);
                    } else if (input.is("select")) {
                        input.find('option[value="' + value + '"]').prop("selected", true);
                    } else {
                        switch (input.attr("type")) {
                            case "datetime-local":
                                value = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                break;
                            case "date":
                                value = moment(value).format("YYYY-MM-DD");
                                break;
                        }
                        input.val(value);
                    }
                }
            });
        }
    }
    return jQuery(this);
};

jQuery.fn.isValid = function () {
    var form = jQuery(this);
    var valid = true;
    var fields = form.find(':required');

    form.find('.error').removeClass('error');
    jQuery.each(fields, function (i, input) {
        switch (jQuery(input).prop('type')) {
            case 'select-one':
                if (
                    jQuery(input).find('option:selected').length === 0 ||
                    jQuery(input).find('option:selected').val() == ''
                ) {
                    jQuery(input).parent().addClass('error');
                    valid = false;
                }
                break;
            case 'checkbox':
                let siblingsChecked = jQuery('[name="' + this.name + '"]:checked').length;
                const min = jQuery(this).data('min');
                const max = jQuery(this).data('max');

                if (
                    (!this.checked && siblingsChecked === 0) // No checkbox checked and is required
                    || (siblingsChecked > 0 && (
                        (min > 0 && siblingsChecked < min) // Less than minimum
                        || (max > 0 && siblingsChecked > max) // More than maximum
                    ))
                ) {
                    jQuery(input).parent().addClass('error');
                    valid = false;
                }
                break;
            default:
                if (!jQuery(input).val()) {
                    jQuery(input).parent().addClass('error');
                    valid = false;
                }
        }
    });

    if (valid) {
        return true;
    } else {
        return false;
    }
};