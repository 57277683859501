var jquery = require('jquery');
window.$ = window.jQuery = jquery;


$(function() {
    $(document).on('click', '.app-dropdown', function () {
        $(this).toggleClass('open');
    });

    $(document).on('click', '.app-collapse .app-button', function () {
        $(this).parent().toggleClass('open');
    });
});
