import { settings } from '../libraries/settings.js';
import { auth } from '../libraries/auth.js';
import { db } from '../libraries/db.js';

import { toast } from '../libraries/toast.js';
import { tools } from '../libraries/tools.js';
import { forms } from '../libraries/forms.js';

import '../libraries/form.js';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

App.controller('profile', function (page, data) {
	auth().checkToken();

	// Forms
	$(page).find('.form-account-wrap').form(forms.account, { id: 'account', classes: 'rounded' });
	$(page).find('.form-application-wrap').form(forms.application, { id: 'application', classes: 'rounded' });
	$(page).find('.form-profile-wrap').form(forms.profile, { id: 'profile', classes: 'rounded' });
	$(page).find('.form-emergency-wrap').form(forms.emergency, { id: 'emergency', classes: 'rounded' });
	$(page).find('.form-studies-wrap').form(forms.studies, { id: 'studies', classes: 'rounded' });

	$(page).on('appReady', function () {
		gtag('event', 'screen_view', { screen_name: 'Cuenta' });

		tools().showSupportNav(page);

		// Avatar
		db().getTrip().then(function (trip) {
			let application = trip.application;
			if (application.member_avatar) {
				let avatar_url = [settings.contentUrl, 'participants', application.member_id, application.member_avatar + '?' + moment().format('X')].join('/');
				$(page).find('.profile-avatar').css('background-image', 'url("' + avatar_url + '")');
			}
		});

		$(page).find(".upload-avatar-trigger").on('click', function () {
			$(page).find('.type-' + $(this).data('type')).trigger('click');
		});

		$(page).find('.upload-file-input').on('change', function (e) {
			$(page).addClass('app-block-touch');
			toast().show('<span>Subiendo Foto de Perfil</span><span class="file-1">0%</span>', 'info', true, false, 'uploading d-flex justify-content-between');

			let formData = new FormData();
			formData.append('file', this.files[0]);
			var ajax = new XMLHttpRequest();

			ajax.upload.addEventListener("progress", function (e) {
				if (e.lengthComputable) {
					let status = e.loaded / e.total;
					status = parseInt(status * 100);
					$(".app-toast.uploading span.file-1").html(status + "%");
				}
			}, false);

			ajax.addEventListener("load", function (e) {
				$(page).removeClass('app-block-touch');
				$(".app-toast.uploading").remove();

				let response = JSON.parse(this.response);
				toast().show(response.html, response.class);
				App.load('application', { tripId: db().getCurrentTripId() });
			}, false);

			ajax.addEventListener("error", function () {
				$(page).removeClass('app-block-touch');
				$(".app-toast.uploading span.file-1").html("Error");
			}, false);

			ajax.open('POST', settings.restUrl + '/member/' + settings.member.member_id + '/avatar');
			ajax.setRequestHeader("X-WETAG-Token", settings.token);
			ajax.send(formData);

		});

		// Forms
		let memberId = data.memberId || false;
		db().getMember(memberId).then(function (data) {
			$(page).find('.account-form').fill(data);
			$(page).find('.profile-form').fill(data);
			$(page).find('.emergency-form').fill(data);

			db().getTrip().then(function (trip) {
				$(page).find('.application-form').fill(trip.application);
				$(page).find('.studies-form').fill(trip.application);
				$(page).find('.app-loader').fadeOut();
			});
		});

		$(page).find('.save-profile-button').on('click', function () {
			$(this).parents('form').trigger('submit');
		});

		$(page).find('.account-form, .profile-form, .emergency-form').on('submit', function (e) {
			e.preventDefault();
			e.stopPropagation();

			if ($(this).isValid()) {
				toast().show('Guardando Perfil', 'warning', 2000);

				fetch(settings.restUrl + '/member/' + settings.member.member_id, {
					method: 'POST',
					headers: {
						'X-WETAG-Token': settings.token,
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					body: $(this).serialize()
				})
					.then((response) => {
						return response.json();
					})
					.then((response) => {
						db().getMember(settings.member.member_id);
						toast().show(response.html, response.class);
					});
			}
		});

		$(page).find('.application-form, .studies-form').on('submit', function (e) {
			e.preventDefault();
			e.stopPropagation();

			if ($(this).isValid()) {
				toast().show('Guardando Perfil', 'warning', 2000);

				fetch(settings.restUrl + '/application/' + settings.trip.application.application_id, {
					method: 'POST',
					headers: {
						'X-WETAG-Token': settings.token,
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					body: $(this).serialize()
				})
					.then((response) => {
						return response.json();
					})
					.then((response) => {
						db().getMember(settings.member.member_id);
						toast().show(response.html, response.class);
					});
			}
		});

		// Applications
		db().getApplications().then(function (data) {
			db().getCurrentTripId().then(function (currentTripId) {

				let applicationsList = $(page).find('.applications-list');
				let applicationNode = applicationsList.find('li:first-child');
				applicationNode.remove();

				if (Object.keys(data).length > 0) {
					applicationsList.append('<li class="label mt-5 fs-5">Applicaciones</li>');

					let applicationsByDate = Object.values(data).sort(
						function (a, b) {
							let c = moment(a.date),
								d = moment(b.date);
							return d - c;
						}
					);

					$.each(applicationsByDate, function (i, application) {
						let item = applicationNode.clone();
						item.data('id', application.application_id);

						item.find('.card-title').html(
							"<b class='program-name'>" + application.program_label +
							"</b> / <small>Opción</small> <b>" + application.subprogram_label + "</b>"
						);
						item.find('.card-details').html(
							"Año <b>" + application.year +
							"</b> / Paso <b>" + application.step_label + "</b>"
						);
						item.on('click', function (e) {
							e.stopPropagation();
							let tripId = $(this).data('id');

							toast().show('Cambiando de Aplicación', 'warning', 2000);

							db().setCurrentTripId(tripId);
							db().getTrip(tripId).then(function () {
								App.load('application');
							});
						});

						if (currentTripId == application.application_id) {
							item.find('section').addClass('bg-yellow-subtle');
						}

						applicationsList.append(item);
					});
				}
			});
		});

		// Version
		$(page).find('.version-info').html('Versión ' + settings.version);

		// Logout
		$(page).find('.button-logout').on('click', function () {
			App.dialog({
				title: 'Cerrar Sesión?',
				okButton: 'Aceptar',
				cancelButton: 'Cancelar',
			}, function (confirmed) {
				if (confirmed) auth().deleteToken();
			}
			);
		});

		// Reinstall
		$(page).find('.button-reinstall').on('click', function () {
			App.dialog({
				title: 'Reinstalar la Aplicación',
				okButton: 'Aceptar',
				cancelButton: 'Cancelar',
			}, function (confirmed) {
				if (confirmed) {
					if ('serviceWorker' in navigator) {
						caches.delete(settings.version);
						navigator.serviceWorker
							.getRegistrations()
							.then(function (registrations) {
								for (let registration of registrations) {
									registration.unregister();
								}
								window.location.replace(window.location.href);
							});
					}
				}
			}
			);
		});

	});
});
