import { settings } from '../libraries/settings.js';
import { db } from '../libraries/db.js';

import { toast } from '../libraries/toast.js';
import { uploader } from '../libraries/uploader.js';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import PullToRefresh from 'pulltorefreshjs';

App.controller('tournament', function (page, data) {
    $(page).on('appReady', function () {
        gtag('event', 'screen_view', { screen_name: 'Copa' });

        let challengesList = false;
        db().getTrip().then(function (data) {

            if (data) {
                let application = data.application;
                const appFileName = '2024-' + application.application_id;

                // Retrieve Challenges
                fetch(settings.restUrl + '/tournament/challenges', {
                    cache: 'no-cache',
                    headers: {
                        'X-WETAG-Token': settings.token
                    }
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((challenges) => {
                        challengesList = challenges;

                        // Retrieve Fotos Uploaded
                        fetch(settings.restUrl + '/uploads/tournament/2024?_' + new Date().getTime(), {
                            cache: 'no-cache',
                            headers: {
                                'X-WETAG-Token': settings.token
                            }
                        })
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                if (response.files) {
                                    $.each(response.files, function (i, file) {
                                        const fileName = file.split('.')[0];
                                        const parts = fileName.split('!')
                                        const c = parts[0].split('-')[2];

                                        addChallengePhoto({
                                            file: file,
                                            name: parts[1],
                                            date: parts[2],
                                            i: c,
                                            class: parts[0]
                                        });
                                    });

                                    return Object.values(response.files);
                                } else {
                                    return [];
                                }
                            })
                            .then((files) => {
                                const appUploads = files.length > 0 ? files
                                    .filter(item => item.startsWith(appFileName))
                                    .map(item => item.split('!')[0]) : [];

                                if (challenges) {
                                    for (let c = 0; c < challenges.length; c++) {
                                        if (moment(challenges[c][1]).isAfter(moment())) {
                                            const challengeUploaded = appUploads.includes(appFileName + '-' + c);
                                            $(page).find('.challenge-action').html(challenges[c][0]);

                                            let btnUploader = $(page).find('.challenge-uploader');
                                            btnUploader.data('i', c);
                                            if (challengeUploaded) {
                                                $(page).find('.challenge-title')
                                                    .html('Desafío Completo!')
                                                    .removeClass('text-white').addClass('text-yellow');
                                                btnUploader.html('Reemplazar Foto').addClass('completed');
                                            }
                                            break;
                                        }
                                    };
                                }

                                $(page).find('.app-loader').fadeOut();
                            });
                    });

                // Uploader
                $(page).find('.challenge-uploader').on('click', function (e) {
                    e.preventDefault();
                    const i = $(this).data('i');
                    const timestamp = moment().valueOf();
                    const nickname = (application.member_nickname || application.member_name).replace(' ', '-');
                    const name = appFileName + '-' + i + '!' + nickname + '!' + timestamp;

                    uploader().open({
                        limit: 1,
                        endpoint: '/upload/tournament',
                        rename: name,
                        accept: 'image/*,video/*',
                        success: function (thumbnails, responses) {
                            $('.' + appFileName + '-' + i).remove();
                            try {
                                const response = JSON.parse(responses[0]);
                                addChallengePhoto({
                                    file: response.file,
                                    name: nickname,
                                    date: timestamp,
                                    i: i,
                                    class: appFileName + '-' + i
                                });
                            } catch (error) {
                                App.load('tournament');
                                toast().show('Error: ' + error, 'danger');

                            }
                        }
                    });
                });
            }

        });

        function addChallengePhoto(item) {
            let post = $('<div>', {
                class: 'post d-flex flex-column ' + item.class
            });
            let img = new Image();
            let label = '<b>' + item.name.replace('-', ' ') + '</b><br>para el desafío <b>' + challengesList[parseInt(item.i)][2] + '</b>';

            img.onload = function () {
                post.append(img);
                post.append('<div class="post-label p-2 p-md-3 rounded-bottom align-items-center"><span>' + label + '</span> <i>' + moment(item.date, 'x').fromNow() + '</i></div>');
            };
            img.src = settings.contentUrl + '/others/copa/' + item.file + '?_' + new Date().getTime();
            img.classList = 'img-fluid rounded';

            $(page).find('.feed').prepend(post);
        }

        PullToRefresh.init({
            mainElement: '.app-content',
            triggerElement: '.page-tournament',
            instructionsPullToRefresh: 'Arrastrá hacia abajo para actualizar',
            instructionsReleaseToRefresh: 'Soltá para actualizar',
            instructionsRefreshing: 'Actualizando',
            onRefresh() {
                App.load('tournament');
            },
            shouldPullToRefresh: function () {
                return !this.mainElement.scrollTop;
            }
        });
    });

});