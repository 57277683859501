var forms = {
    account: {
        submit: { type: "submit", classes: "d-none" },
        nickname: { label: "Apodo", type: "text", required: true, col: 4 },
        email: { label: "Email", type: "email", required: true, col: 8 },
        password: {
            label: "Nueva Contraseña",
            type: "text",
            placeholder: "Solo si querés reemplazar tu contraseña actual",
            col: 12
        },
        button: {
            label: "Actualizar Cuenta",
            type: "button",
            classes: "bg-dark save-profile-button disable-offline",
            col: 12,
        }
    },
    profile: {
        submit: { type: "submit", classes: "d-none" },
        name: { label: "Nombre Completo", type: "text", required: true },
        lastname: { label: "Apellido Completo", type: "text", required: true },
        dni: { label: "DNI", type: "number", required: true },
        phone: { label: "Celular", type: "number", required: true },
        dob: { label: "Fecha de Nacimiento", type: "date", required: true },
        gender: {
            label: "Género",
            type: "select",
            options: [
                { label: "Sin Especificar", value: "", selected: true },
                { label: "Masculino", value: 1 },
                { label: "Femenino", value: 2 },
            ],
        },
        address: {
            label: "Domicilio",
            type: "text",
            placeholder: "Completa",
            required: true,
            col: 12
        },
        city: { label: "Ciudad", type: "text", required: true },
        province: {
            label: "Provincia",
            type: "select",
            required: true,
            options: [
                { label: "", value: "", selected: true }, { label: "CABA", value: 1 }, { label: "Buenos Aires", value: 2 }, { label: "Catamarca", value: 3 }, { label: "Chaco", value: 4 }, { label: "Chubut", value: 5 }, { label: "Cordoba", value: 6 }, { label: "Corrientes", value: 7 }, { label: "Entre Rios", value: 8 }, { label: "Formosa", value: 9 }, { label: "Jujuy", value: 10 }, { label: "La Pampa", value: 11 }, { label: "La Rioja", value: 12 }, { label: "Mendoza", value: 13 }, { label: "Misiones", value: 14 }, { label: "Neuquen", value: 15 }, { label: "Rio Negro", value: 16 }, { label: "Salta", value: 17 }, { label: "San Juan", value: 18 }, { label: "San Luis", value: 19 }, { label: "Santa Cruz", value: 20 }, { label: "Santa Fe", value: 21 }, { label: "Santiago del Estero", value: 22 }, { label: "Tierra del Fuego", value: 23 }, { label: "Tucuman", value: 24 }, { label: "Otro", value: 25 }
            ]
        },
        passport: { label: "Pasaporte", type: "text", col: 12, colMD: 4 },
        instagram: { label: "Instagram", type: "text", col: 6, colMD: 4 },
        skype: { label: "Skype", type: "text", col: 6, colMD: 4 },
        button: {
            label: "Actualizar Información",
            type: "button",
            classes: "bg-dark save-profile-button disable-offline",
            col: 12,
        },
    },
    application: {
        submit: { type: "submit", classes: "d-none" },
        public: { 
            label: "Visibilidad: permite a otros WETAGers de tu zona contactarte", type: "select", col: 12,
            options: [
                { label: "Pública", value: 1, selected: true },
                { label: "Privada", value: 2 },
            ]
        },
        button: {
            label: "Actualizar Aplicación",
            type: "button",
            classes: "bg-dark save-profile-button disable-offline",
            col: 12,
        },
    },
    emergency: {
        submit: { type: "submit", classes: "d-none" },
        additional_contact_name_1: { label: "Nombre Completo", type: "text", required: true, col: 12, colMD: 4 },
        additional_contact_phone_1: { label: "Celular", type: "number", required: true, col: 6, colMD: 3 },
        additional_contact_email_1: { label: "Email", type: "email", required: true, col: 6, colMD: 5 },
        additional_contact_payer_1: { label: "Esta persona abona mi viaje", type: "checkbox", col: 12 },
        additional_contact_name_2: { label: "Nombre Completo", type: "text", col: 12, colMD: 4 },
        additional_contact_phone_2: { label: "Celular", type: "number", col: 6, colMD: 3 },
        additional_contact_email_2: { label: "Email", type: "email", col: 6, colMD: 5 },
        additional_contact_payer_2: { label: "Esta persona abona mi viaje", type: "checkbox", col: 12 },
        button: {
            label: "Actualizar Contactos",
            type: "button",
            classes: "bg-dark save-profile-button disable-offline",
            col: 12,
        },
    },
    studies: {
        submit: { type: "submit", classes: "d-none" },
        university: { label: "Universidad", type: "text", required: true },
        major: { label: "Carrera", type: "text", required: true },
        //    studies_is_onsite: { label: "Curso la carrera en la modalidad presencial o estoy obligado a cursar presencialmente al menos un día a la semana.", type: "checkbox", readonly: true, col: 12 },
        //    studies_start_year: { label: "Año de Inicio", type: "number", readonly: true, col: 4 },
        //    studies_graduation_year: { label: "Año de Graduación", type: "number", readonly: true, col: 4 },
        //    studies_evolution: { label: "Evolución %", type: "number", readonly: true, col: 4 },
        //    studies_anual_subjects: { label: "Materias Anuales", type: "number", readonly: true, col: 4 },
        //    studies_first_semester_subjects: { label: "Materias 1er Cuatrimestre", type: "number", readonly: true, col: 4 },
        //    studies_second_semester_subjects: { label: "Materias 2do Cuatrimestre", type: "number", readonly: true, col: 4 },
        button: {
            label: "Actualizar Estudios",
            type: "button",
            classes: "bg-dark save-profile-button disable-offline",
            col: 12,
        },
    },
    payment: {
        submit: { type: "submit", classes: "d-none" },
        date: { label: "Fecha del Pago", type: "date", required: true, col: 12 },
        method: {
            label: "Forma de Pago", type: "select", required: true, col: 7,
            options: [
                { label: "", value: "", selected: true },
                { label: "Depósito USD desde Argentina", value: 159 },
                { label: "Transferencia USD desde Argentina", value: 159 },
                { label: "Tarjeta de Crédito", value: 361 },
                { label: "Transferencia USD desde el Exterior", value: 360 },
                { label: "Crypto", value: 330 },
            ]
        },
        amount: { label: "Monto en U$D", type: "number", required: true, col: 5 }
    }
};

export { forms };