import { db } from './db.js';

import { toast } from './toast.js';

var tools = function () {
	let userAgent = window.navigator.userAgent.toLowerCase();
	return {
		isMobile: function () {
			return this.isAndroid() || this.isIOS();
		},
		isAndroid: function () {
			return /android/.test(userAgent);
		},
		isIOS: function () {
			return /iphone|ipad|ipod/.test(userAgent);
		},
		isStandaloneMode: function () {
			return (
				("standalone" in window.navigator && window.navigator.standalone) ||
				window.matchMedia("(display-mode: standalone)").matches
			);
		},
		showSupportNav: function (page) {
			if (page) {
				$.when(db().getTrip()).then(function (data) {
					if (data.step.support) {
						$(page).find('.open-support')
							.on('click', function () {
								window.open('whatsapp://send?phone=' + data.step.support, '_system', 'hidden=yes');
							});
					} else {
						$(page).find('.open-support').hide();
					}
				});
			}
		},
		setPexelBackground: function (target, query = false) {
			let queries = [
				'new york city',
				'san francisco city',
				'chicago city',
				'los angeles city',
				'big sur california',
				'grand canyon arizona'
			];
			query = query || queries[Math.floor(Math.random() * queries.length)];

			if (navigator.onLine) {

				fetch(
					"https://api.pexels.com/v1/search?per_page=20&orientation=landscape&query=" +
					query, {
					headers: {
						Authorization: "563492ad6f917000010000011efc2cd960524ff7983aefaf4eee32dc",
					},
				}
				)
					.then((response) => {
						return response.json();
					})
					.then((data) => {
						let index = Math.floor(Math.random() * 20);
						let photo = data.photos[index];
						let width = 800;
						if ($('body').hasClass('app-desktop')) width = 1500;
						$(target)
							.attr("data-text", photo.alt)
							.css("background-image", "url(" + photo.src.original + "?auto=compress&cs=tinysrgb&w=" + width + ")"
							);
					})
					.catch(error => {
						console.log(error);
					});
			}
		}
	};
};


function templating(template, groups) {
	$.each(groups, function (group, fields) {
		$.each(fields, function (field, value) {
			value = typeof value !== 'undefined' && value != null ? value : '';
			template = template.replace(RegExp('{{' + group + '.' + field + '}}', 'g'), value);
		});
	});
	return template;
}

function copyToClipboard(text) {
	navigator.clipboard.writeText(text).then(() => {
		toast('Link Copiado', 'info');
	}, () => {
		toast('Error al copiar el link', 'danger');
	});
}

function throwConfetti() {
	let W = window.innerWidth;
	let H = window.innerHeight;
	const canvas = document.getElementById("canvas");
	const context = canvas.getContext("2d");
	const maxConfettis = 150;
	const particles = [];

	const possibleColors = [
		"DodgerBlue",
		"OliveDrab",
		"Gold",
		"Pink",
		"SlateBlue",
		"LightBlue",
		"Gold",
		"Violet",
		"PaleGreen",
		"SteelBlue",
		"SandyBrown",
		"Chocolate",
		"Crimson"
	];

	function randomFromTo(from, to) {
		return Math.floor(Math.random() * (to - from + 1) + from);
	}

	function confettiParticle() {
		this.x = Math.random() * W; // x
		this.y = Math.random() * H - H; // y
		this.r = randomFromTo(11, 33); // radius
		this.d = Math.random() * maxConfettis + 11;
		this.color =
			possibleColors[Math.floor(Math.random() * possibleColors.length)];
		this.tilt = Math.floor(Math.random() * 33) - 11;
		this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
		this.tiltAngle = 0;

		this.draw = function () {
			context.beginPath();
			context.lineWidth = this.r / 2;
			context.strokeStyle = this.color;
			context.moveTo(this.x + this.tilt + this.r / 3, this.y);
			context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
			return context.stroke();
		};
	}

	function Draw() {
		const results = [];

		// Magical recursive functional love
		requestAnimationFrame(Draw);

		context.clearRect(0, 0, W, window.innerHeight);

		for (var i = 0; i < maxConfettis; i++) {
			results.push(particles[i].draw());
		}

		let particle = {};
		let remainingFlakes = 0;
		for (var i = 0; i < maxConfettis; i++) {
			particle = particles[i];

			particle.tiltAngle += particle.tiltAngleIncremental;
			particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
			particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

			if (particle.y <= H) remainingFlakes++;

			// If a confetti has fluttered out of view,
			// bring it back to above the viewport and let if re-fall.
			if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
				particle.x = Math.random() * W;
				particle.y = -30;
				particle.tilt = Math.floor(Math.random() * 10) - 20;
			}
		}

		return results;
	}

	window.addEventListener(
		"resize",
		function () {
			W = window.innerWidth;
			H = window.innerHeight;
			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;
		},
		false
	);

	// Push new confetti objects to `particles[]`
	for (var i = 0; i < maxConfettis; i++) {
		particles.push(new confettiParticle());
	}

	// Initialize
	canvas.width = W;
	canvas.height = H;
	Draw();
}

export { tools, templating, throwConfetti }