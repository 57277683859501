import gtag from 'ga-gtag';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

App.controller('reader', function (page, data) {
    this.restorable = false;

    if (!('acf' in data)) data.acf = { type: 'standard' }

    $(page).on('appReady', function () {
        gtag('event', 'page_view', { page_title: data.title });

        $(page).find('.post-title').html(data.title);
        $(page).find('.post-content').html(data.content);

        switch (data.acf.type) {
            case 'youtube':
                $(page).find('#reader-player').attr('data-plyr-embed-id', data.acf.youtube_id).parent().removeClass('d-none');
                const player = new Plyr($('#reader-player'), {
                    autoplay: true,
                    muted: true,
                    controls: ['play-large', 'progress', 'pip', 'airplay', 'mute'],
                    youtube: { noCookie: true }
                });

                player.on('ready', (event) => {
                    const instance = event.detail.plyr;
                    console.log(instance);
                });
                $(page).addClass('dark-mode');
                $(page).find('.section-hero').remove();

                if (!data.content) {
                    $(page).find('.section-player').addClass('h-100 d-flex align-items-center');
                }
                break;

            default:
                if (data.cover) {
                    $(page)
                        .find('.section-hero')
                        .css('background-image', 'url(' + data.cover + ')');
                    $(page).find('.post-title').addClass('text-white h1 text-center');
                } else {
                    $(page).find('.section-hero').removeClass('section-hero').addClass('app-desktop-wrapper');
                    $(page).find('.post-title').addClass('mt-4 mb-n3 h2');
                }

                break;
        }

        $(page).find('.app-loader').fadeOut();

        $(page)
            .find('.follow-link')
            .on('click', function (e) {
                e.preventDefault();
                window.open(this.href, '_blank', 'location=no');
            });
    });
});
