import { settings } from './settings.js';
import { auth } from './auth.js';
import { toast } from './toast.js';

import localforage from 'localforage/dist/localforage.min.js';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

var db = function () {
    return {
        getMember: function (memberId = false) {
            return new Promise((resolve, reject) => {
                localforage.getItem('member').then(function (table) {
                    let expired = table ? moment().diff(moment(table.timestamp), 'days', true) > 1 : true;

                    if (table && !memberId && !expired) {
                        settings.member = table.data;
                        resolve(table.data);
                    } else {
                        memberId = memberId || table.data.member_id;
                        fetch(settings.restUrl + '/member/' + memberId + '?reload', {
                            cache: 'no-cache',
                            headers: {
                                'X-WETAG-Token': settings.token
                            }
                        })
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                if (response.class == 'success') {
                                    settings.member = response.data;
                                    settings.applications = response.data.applications;

                                    localforage.setItem('member', {
                                        timestamp: moment().format(),
                                        data: response.data,
                                    });
                                    localforage.setItem('applications', {
                                        timestamp: moment().format(),
                                        data: response.data.applications,
                                    });

                                    // Set Current Trip Id
                                    localforage.getItem('currentTripId').then(function (tripId) {
                                        if (!tripId) {
                                            // Sort Applications by year DESC
                                            let applicationsByDate = Object.values(response.data.applications)
                                                .sort(function (a, b) {
                                                    //var c = moment(a.date), d = moment(b.date);
                                                    return b.year - a.year;
                                                });
                                            let lastApplicationId = applicationsByDate[0].application_id;
                                            localforage.setItem('currentTripId', lastApplicationId);
                                        }
                                    }).catch(function (error) {
                                        console.log(error);
                                    });

                                    resolve(response.data);
                                } else {
                                    toast().show(response.html, response.class);
                                    
                                    if (response.class == 'danger') {
                                        auth().deleteAll();
                                    }
                                    reject(response.html);
                                }
                            });
                    }
                }).catch(function (error) {
                    console.log(error);
                    reject('Almacenamiento Inexistente');
                });
            });
        },
        getApplications: function () {
            return new Promise((resolve, reject) => {
                localforage.getItem('applications').then(function (table) {
                    let expired = table ? moment().diff(moment(table.timestamp), 'hours', true) > 1 : true;

                    if (table && !expired) {
                        resolve(table.data);
                    } else {
                        db().getMember().then(function (member) {
                            if (member && 'applications' in member) {
                                resolve(member.applications);
                            } else {
                                reject(null);
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                    reject('Almacenamiento Inexistente');
                });
            });
        },
        getSubprogram: function (subprogramId = false) {
            return new Promise((resolve, reject) => {
                localforage.getItem('subprograms').then(function (table) {
                    let expired = table ? moment().diff(moment(table.timestamp), 'days', true) > 30 : true;

                    if (table && !subprogramId && !expired) {
                        settings.subprogram = table.data;
                        resolve(table.data);
                    } else if (subprogramId) {
                        fetch(settings.restUrl + '/subprogram/' + subprogramId + '?reload', {
                            cache: 'no-cache',
                            headers: {
                                'X-WETAG-Token': settings.token
                            }
                        })
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                if (response.class == 'success') {
                                    settings.subprogram = response.data;

                                    localforage.setItem('subprogram', {
                                        timestamp: moment(),
                                        data: response.data,
                                    });

                                    resolve(response.data);
                                } else {
                                    toast().show(response.html, response.class);
                                    reject(response.html);
                                }
                            });
                    } else {
                        reject(null);
                    }
                }).catch(function (error) {
                    console.log(error);
                    reject('Almacenamiento Inexistente');
                });
            });
        },
        getCurrentTripId: function (tripId = false) {
            return new Promise((resolve, reject) => {
                if (tripId) resolve(tripId);

                localforage.getItem('currentTripId').then(function (tripId) {
                    if (tripId) {
                        resolve(tripId);
                    } else {
                        // Sort Applications by date DESC
                        localforage.getItem('applications').then(function (applications) {
                            if (applications) {
                                let applicationsByDate = Object.values(applications.data)
                                    .sort(function (a, b) {
                                        var c = moment(a.date),
                                            d = moment(b.date);
                                        return d - c;
                                    });
                                let lastApplicationId = applicationsByDate[0].application_id;
                                db().setCurrentTripId(lastApplicationId);

                                resolve(lastApplicationId);
                            } else {
                                reject(null);
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                    reject('Almacenamiento Inexistente');
                });
            });
        },
        setCurrentTripId: function (tripId) {
            if (tripId) {
                localforage.setItem('currentTripId', tripId);

                db().getTrip(tripId);
            }
        },
        getTrip: function (tripId = false) {
            return new Promise((resolve, reject) => {
                localforage.getItem('trip').then(function (table) {
                    let expired = table ? moment().diff(table.timestamp, 'minutes') > 1 : true;

                    if (table && !tripId && !expired) {
                        settings.trip = table.data;
                        resolve(table.data);
                    } else {
                        db().getCurrentTripId(tripId).then(function (tripId) {
                            if (tripId) {
                                fetch(settings.restUrl + '/trip/' + tripId + '?reload', {
                                    cache: 'no-cache',
                                    headers: {
                                        'X-WETAG-Token': settings.token
                                    }
                                })
                                    .then((response) => {
                                        return response.json();
                                    })
                                    .then((response) => {
                                        if (response.class == 'success') {
                                            settings.trip = response.data;

                                            localforage.setItem('trip', {
                                                timestamp: moment().format(),
                                                data: response.data,
                                            });

                                            resolve(response.data);
                                        } else {
                                            toast().show(response.html, response.class);
                                            if (response.class == 'danger') {
                                                auth().deleteAll();
                                            }
                                            reject(response.html);
                                        }
                                    });
                            } else {
                                auth().deleteAll();
                                reject('Viaje Inexistente');
                            }
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                    reject('Almacenamiento Inexistente');
                });
            });
        }
    };
};

export { db };