import { settings, videoTutorialOptions } from '../libraries/settings.js';

import YouTubePlayer from 'youtube-player';

App.controller('upload-link', function (page, data) {
    this.restorable = false;

    // Title & Details
    $(page).find(".upload-title").append(data.name);
    if (data.instructions) {
        $(page).find(".upload-details").html(data.instructions);
    } else {
        $(page).find(".upload-details").remove();
    }

    $(page).on('appReady', function () {

        // Photo Example
        if (data.example) {
            $(page)
                .find(".upload-example")
                .html('<img src="' + settings.contentUrl + '/others/actions/' + data.example + '" class="img-fluid rounded">')
                .show();
        } else {
            $(page).find(".upload-example").remove();
        }
        // Video Tutorial
        if (data.video) {
            let player = YouTubePlayer("upload-video", Object.assign({
                videoId: data.video,
                width: $(page).find("#upload-video").width()
            }, videoTutorialOptions));

            $(page).find(".upload-video").css('visibility', 'visible');
        } else {
            $(page).find(".upload-video").remove();
        }

        $(page).find('.app-loader').fadeOut();

        $(page)
            .find(".upload-follow-link")
            .on("click", function (e) {
                e.stopImmediatePropagation();
                window.open(data.url, '_blank', 'location=no');
            });
    });
});
