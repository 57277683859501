import { auth } from '../libraries/auth.js';
import { toast } from '../libraries/toast.js';

import { gtag, install } from 'ga-gtag';

import localforage from 'localforage/dist/localforage.min.js';

// Google Analytics
install('G-Z1TCCW3ELM', { 'send_page_view': false });

// Storage
localforage.config({
    driver: localforage.INDEXEDDB,
    name: 'WETAG',
    version: 1.0,
    storeName: 'main'
});

// Callback Loader
setTimeout(() => {
    let loader = $('.app-loader');
    if (loader && loader.is(':visible')) {
        setTimeout(() => {
            let loader = $('.app-loader');
            if (loader && loader.is(':visible')) {
                toast().show(
                    'Algo no está funcionando correctamente, volve a iniciar sesión haciendo click acá.',
                    "warning",
                    5000,
                    function () {
                        auth().deleteAll();
                    }
                );

            }
        }, 2000);
    }
}, 15000);

// Online / Offline
window.addEventListener('online', () => {
    $('.toast-offline').remove();
    $('body').addClass('app-online');
    App.load('application');
});

window.addEventListener('offline', () => {
    $('body').removeClass('app-offlne').addClass('app-offline');

    toast().show(
        'No estás conectado a internet. Algunas acciones pueden estar desactivadas.',
        "warning",
        true,
        false,
        'toast-offline'
    );
});
if (!navigator.onLine) document.body.classList.add('app-offline');


window.addEventListener('beforeinstallprompt', (event) => {
    event.userChoice.then((response) => {
        gtag('event', 'add_to_home', {
            value: response.outcome
        });
    });
});
