var toast = function () {
    return {
        show: function (
            html = '',
            type = 'success',
            permanent = false, // or Delay
            click = 'exit',
            classes = false
        ) {
            const effectIn = 'animate__flipInX';
            const effectOut = 'animate__flipOutX';
                
            var toast = $('<div>', {
                class: 'animate__animated ' + effectIn + ' app-toast ' + type,
                html: html
            });

            let words = html.split(' ').length * 250;
            if (words < 4000) words = 4000;
            let time = Number.isInteger(permanent) ? permanent : words;

            if (classes) toast.addClass(classes);

            if (click) {
                if (click === 'exit') {
                    toast.on('click', function (e) {
                        e.stopPropagation();
                        $(this)
                            .removeClass(effectIn)
                            .addClass(effectOut)
                            .on('animationend', function () {
                                $(this).remove();
                            });
                    });
                } else {
                    toast.on('click', function (e) {
                        e.stopPropagation();
                        click();
                    });
                }
            }

            if (permanent === false || Number.isInteger(permanent)) {
                setTimeout(() => {
                    toast
                        .removeClass(effectIn)
                        .addClass(effectOut)
                        .on('animationend', function () {
                            $(this).remove();
                        });
                }, time);
            }

            toast.appendTo($('.app-toaster'));

            return toast;
        },
    };
};

export { toast }