import { auth } from '../libraries/auth.js';
import { tools } from '../libraries/tools.js';

import gtag from 'ga-gtag';

App.controller('login', function (page) {
	this.restorable = false;

	setTimeout(() => {
		$('.app-loader-animated').fadeOut()
		var pwaInstall = document.getElementsByTagName('pwa-install')[0];
		if (!pwaInstall.isUnderStandaloneMode) pwaInstall.showDialog();
	}, 4800);

	$(page).on('appReady', function () {
		gtag('event', 'screen_view', { screen_name: "Login" });

		$(page).find(".app-toggle-password").on("click", function () {
			if ($(this).text() === "visibility") {
				$(this).text("visibility_off").prev().attr("type", "text");
			} else {
				$(this).text("visibility").prev().attr("type", "password");
			}
		});

		$(page).find(".button-recover").on("click", function () {
			$(page).find(".form").addClass("recover");
			$(page).find("#field-password").prop("required", false);
			$(page).find(".button-submit").html("Recuperar Contraseña");
		});

		$(page).find(".button-login").on("click", function () {
			$(page).find(".form").removeClass("recover");
			$(page).find("#field-password").prop("required", true);
			$(page).find(".button-submit").html("Entrar");
		});

		$(page).find(".button-submit").on("click", function () {
			$(page).find(".form").trigger("submit");
		});

		$(page).find(".form").on("submit", function (e) {
			e.preventDefault();
			if ($(this).isValid()) {
				$(page).addClass('app-block-touch');

				if ($(this).hasClass("recover")) {
					auth().recoverPassword(
						$(this).find('[name="username"]').val(),
						function () {
							$(this).removeClass("recover");
							$(page).removeClass('app-block-touch');
							$(page).find(".button-login").trigger("click");
						},
						function () {
							$(page).removeClass('app-block-touch');
						}
					);
				} else {
					auth().getToken(
						$(this).find('[name="username"]').val(),
						$(this).find('[name="password"]').val(),
						function () {
							gtag("event", "login", {
								method: "WETAG Credentials"
							});
							if (tools().isStandaloneMode())
								gtag("event", "installed", {
									event_category: "PWA",
									event_label: "Installed",
								});

							App.load("application");
						},
						function () {
							$(page).removeClass('app-block-touch');
						}
					);
				}
			}
		});
	});
});
