import { settings } from './settings.js';
import { templating } from '../libraries/tools.js';
import { toast } from './toast.js';

var dialog;
let files = [];
let thumbnails = [];
let responses = [];

var uploader = function () {
    return {
        open: function (options = { endpoint: false, rename: false, limit: false, success: false, accept: 'image/*,.pdf' }) {
            if (!options.endpoint) {
                toast().show('Falta setear el endpoint del uploader', 'danger');
                return false;
            }

            fetch('uploader.html')
                .then((response) => {
                    return response.text();
                })
                .then((response) => {
                    dialog = $(templating(response, {
                        accept: { types: options.accept }
                    }));

                    dialog.find('.upload-file-trigger').on('click', function () {
                        const type = $(this).data('type');
                        switch (type) {
                            case 'camara':
                                uploader().openCamara();
                                break;
                            case 'library':
                                uploader().openLibrary();
                                break;
                        }
                    });

                    dialog.find('.upload-file-input').on('change', function (e) {
                        if (options.limit === false || files.length < options.limit) {
                            uploader().pickFile(this.files[0]);
                            this.value = '';
                        } else {
                            toast().show('Se pueden cargar un total de ' + options.limit + ' archivo' + (options.limit > 1 ? 's' : ''), 'warning');
                        }
                    });

                    dialog.find('.upload-files').data('options', options);
                    dialog.find('.upload-files').on('click', function () {
                        uploader().upload($(this).data('options'))
                            .then(function () {
                                if (options.success) {
                                    options.success(thumbnails, responses);
                                }
                            })
                            .then(function () {
                                uploader().close();
                            })
                    });

                    dialog.find('.upload-close').on('click', function () {
                        uploader().close();
                    });

                    $('.app-page').append(dialog);
                });
        },
        openCamara: function () {
            dialog.find('.type-camara').trigger('click');
        },
        openLibrary: function () {
            dialog.find('.type-library').trigger('click');
        },
        pickFile: function (file) {
            let c = dialog.find('.upload-files-list > li').length;
            let item = $('<li>', {
                class: 'animate__animated animate__flipInX',
                'data-i': c
            });

            let reader = new FileReader();
            reader.onload = function (e) {
                let thumbnail = $('<img>', {
                    src: e.target.result,
                    class: 'img-fluid'
                });
                item.append(thumbnail);
                thumbnails.push(e.target.result);
            };
            reader.readAsDataURL(file);
            files.push(file);

            item.on('click', function (e) {
                e.stopImmediatePropagation();
                let item = $(this);

                App.dialog({
                    title: 'Borrar Archivo ?',
                    okButton: 'Aceptar',
                    cancelButton: 'Cancelar',
                },
                    function (confirmed) {
                        if (confirmed) {
                            files.splice(item.data('i'), 1);
                            thumbnails.splice(item.data('i'), 1);
                            item.remove();
                        }
                    }
                );
            });

            dialog.find('.upload-files-list').prepend(item);

        },
        upload: function (options = { endpoint: false, rename: false }) {
            return new Promise((resolve, reject) => {
                if (options.endpoint && files.length > 0) {
                    $('.app-page').addClass('app-block-touch');
                    toast().show('<b>Cargando Archivos</b>', 'info', true, false, 'uploading');

                    $.each(files, function (i, file) {
                        const c = i + 1;

                        $('.app-toast.uploading').append('<br>Archivo ' + c + ' <span class="file-' + c + '">0%</span>');

                        let formData = new FormData();
                        formData.append('file', file);
                        formData.append('rename', options.rename);

                        let ajax = new XMLHttpRequest();
                        ajax.upload.addEventListener('progress', function (e) {
                            if (e.lengthComputable) {
                                let status = e.loaded / e.total;
                                status = parseInt(status * 100);
                                $('.app-toast.uploading span.file-' + c).html(status + '%');
                            }
                        }, false);

                        ajax.addEventListener('load', function (e) {
                            responses.push(e.target.response);

                            if (c === files.length) {
                                $('.app-page').removeClass('app-block-touch');
                                $('.app-toast.uploading').addClass('animate__flipOutX').delay(5000).remove();

                                resolve(thumbnails, responses);
                            }
                        }, false);

                        ajax.addEventListener('error', function () {
                            $('.app-toast.uploading span.file-' + c).html('Error');
                        }, false);

                        ajax.open('POST', settings.restUrl + options.endpoint);
                        ajax.setRequestHeader('X-WETAG-Token', settings.token);
                        ajax.send(formData);

                    });
                } else {
                    reject(false);
                }
            });

        },
        close: function () {
            dialog.remove();
            files = [];
            thumbnails = [];
            responses = [];
            dialog = false;
        }
    };
};

export { uploader }